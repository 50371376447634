<template>
   <v-app>
      <v-form ref="form" @submit.prevent="login" class="row justify-center align-center">
         <v-col lg="4" md="6" sm="8" xl="3">
            <v-card dark color="rgba(0, 0, 0, 0.6)">
               <v-card-title>Welcome to eSurvey</v-card-title>
               <v-card-text class="py-0">{{change_pwd ? 'Ganti password': message}}</v-card-text>
               <template v-if="change_pwd">
                  <v-card-text class="px-10">
                     <v-text-field append-icon="mdi-account-key" label="Password Baru" type="password" v-model="fm.password" :rules="pwdRules" />
                     <v-text-field append-icon="mdi-key" label="Password Konfirmasi" type="password" v-model="fm.password2" :rules="[checkPwd]" />
                     <div class="my-4 text-center">
                        <v-btn outlined rounded width="120px" color="primary" type="submit" :loading="loading">submit</v-btn>
                     </div>
                  </v-card-text>
               </template>
               <template v-else>
                  <v-card-text class="px-10">
                     <v-text-field append-icon="mdi-account" label="Login" type="text" v-model="fm.username" :rules="usrRules" />
                     <v-text-field append-icon="mdi-account-key" label="Password" type="password" v-model="fm.password" :rules="pwdRules" />
                  </v-card-text>
                  <v-card-actions class="justify-center pb-8">
                     <v-btn outlined rounded width="120px" color="primary" type="submit" :loading="loading">Login</v-btn>
                  </v-card-actions>
               </template>
            </v-card>
         </v-col>
      </v-form>
      <alert />
   </v-app>
</template>
<script>
// import { mapMutations, mapState } from 'vuex';
import {changePwd,login} from '../api/user';
import Alert from '../components/Alert.vue';
export default {
  components: { Alert },
   data: () => ({
      change_pwd: false,
      fm: {},
      usrRules: [v => !!v || 'Username harus diisi' ],
      pwdRules: [v => !!v || 'Password harus diisi' ],
      loading: false,
      token: null,
      message: 'silahkan login untuk melanjutkan'
   }),
   mounted() {
      this.fm.sid = this.$store.state.auth.sid;
   },
   methods: {
      login() {
         if(!this.$refs.form.validate()) return;
         this.loading = true;
         if(this.change_pwd) {
            this.fm.token = this.token;
            changePwd(this.fm).then(({mess,stat}) => {
               if(stat === 1) {
                  this.$store.dispatch('alert', {title: 'Sukses', text: mess, type: 'success'});
                  this.change_pwd = false;
                  this.$refs.form.reset();
               } else this.$store.dispatch('alert', {title: 'Ooops!', text: mess, type: 'warning'});
            }).finally(() => { this.loading = false });
         } else {
            login(this.fm).then(({stat,mess, ...rsl}) => {
               if(stat === 1) {
                  // this.$store.dispatch('alert', { type: 'success', title: mess, showConfirmButton: false, timer: 1500 });
                  this.$store.dispatch('auth/ok',rsl);
                  this.$router.push('/');
               } else {
                  this.$store.dispatch('alert', {title: 'Ooops!', text: mess, type: 'warning'});
                  this.fm.password = '';
                  if(stat === 4) {
                     this.change_pwd = true;
                     this.$refs.form.reset();
                     this.token = rsl.token;
                  }
               }
            },
            error => {
               this.message = (error.response && error.response.data) || error.message || error.toString();
            }).finally(() => { this.loading = false });
         }
      },
      checkPwd(v) {
         return v === this.fm.password || 'Kedua password tidak sama';
      },
   }
}
</script>
<style lang="css" scoped>
#app::before {
   content: '';
   position: fixed;
   width: 100%;
   height: 100%;
   top: 0;
   left: 0;
   background: url("../assets/blur-bg.jpg") no-repeat center center;
   background-size: cover;
   will-change: transform;
   /* z-index: -1; */
}
form {height: 100%;}
</style>